import WelcomeUI from 'src/ui/welcome'

import { mountComponent } from 'src/utils'

export class WelcomeApp {
  UI = WelcomeUI
  mountComponent = mountComponent;

  constructor() {
    App.modulePending()
    this.ready = true
    App.moduleReady()

  }
}

WelcomeApp.appName = 'WelcomeApp'
