import React from 'react'
import { Provider, ErrorBoundary } from '@rollbar/react';

import 'src/ui/welcome/welcome.css'

const WelcomeUI = () => <Provider instance={App.rollbar}>
  <ErrorBoundary>
    <div className="container-fluid">
      <div className="content">
        <div className="row-fluid">
          <div className="span12">
            <h1>Welcome</h1>
            <p>I'm afraid there's nothing to see here.</p>
          </div>
        </div>
        <footer />
      </div>
    </div>
  </ErrorBoundary>
</Provider>

WelcomeUI.appName = 'WelcomeUI'

export default WelcomeUI
