import dayjs from 'dayjs'
import React from 'react'
import ReactDOM from 'react-dom'

const dataTypeAttributePrefix = 'data-type-'
const dataTypePrefix = 'dataType'

function dataset(data) {
  data = Object.entries(data).flatMap(([k, v]) => {
    let result = [[`data-${k}`, v]]
    let type = typeof (v)
    if (type != 'string' && type != 'undefined') {
      if (type == 'number') type = Number.isInteger(v) ? 'integer' : 'float'
      result.push([`data-${dataTypeAttributePrefix}${k}`, type])
    }
    return result
  })
  data = Object.fromEntries(data)
  return data
}

function parseDataset(dataset) {
  let realKeys = Object.keys(dataset).filter((k) => !k.startsWith(dataTypePrefix))
  let data = realKeys.map((k) => {
    let typeKey = dataTypePrefix + k[0].toUpperCase() + k.substring(1)
    let type = dataset[typeKey] || 'string'
    let value = dataset[k]
    switch (type) {
      case 'integer':
        value = parseInt(value)
        break
      case 'float':
        value = parseFloat(value)
        break
    }
    return [k, value]
  })
  data = Object.fromEntries(data)
  return data
}

function summarizeYearList(years) {
  years = years.sort((a,b) => (a-b))
  let pairStart = 0
  let lastYear = years[pairStart]
  let result = []
  for (var i = 1; i < years.length; i++) {
    if (years[i] != lastYear + 1) {
      if (years[pairStart] == lastYear)
        result.push([years[pairStart]])
      else
        result.push([years[pairStart], lastYear])
      pairStart = i
    }
    lastYear = years[i]
  }
  if (years[pairStart] == lastYear)
    result.push([years[pairStart]])
  else
    result.push([years[pairStart], lastYear])
  return result.map((v) => v.join(v[1] == v[0] + 1 ? ', ' : '-')).join(', ')
}

// function range(start, finish) {
//   const size = (finish - start) + 1
//   return [...Array(size).keys()].map(i => i + start)
// }

function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start
    start = 0
  }

  if (typeof step == 'undefined') {
    step = 1
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return []
  }

  var result = []
  for (var i = start; step > 0 ? i <= stop : i >= stop; i += step) {
    result.push(i)
  }

  return result
}

function yearRange(start = 2000, finish = App.configuration.year) {
  return range(start, finish)
}

function invertObject(data) {
  Object.fromEntries(
    Object
      .entries(data)
      .map(([key, value]) => [value, key])
  )
}

function yearsAgo(years) {
  const now = new Date()
  return new Date(now.getFullYear() - years, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
}

function dateFormat(date, format) {
  if (!date) return null
  if (format == 'short_date')
    format = "DD MMMM YYYY"
  if (format == 'shorter_date')
    format = 'D MMMM'
  return dayjs(date).format(format)
}

function clamp(value, min, max) {
  if (value < min)
    return min
  if (value > max)
    return max
  return value
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

function intersection(a, b) {
  return a.filter(value => b.includes(value))
}

function intersects(a, b) {
  return intersection(a, b).length > 0
}

function difference(a, b) {
  return a.filter(value => !b.includes(value))
}

function arrayEqual(a, b) {
  if(a.length != b.length) return false
  return a.every((element, index) => element === b[index]);
}

function containsFileList(attributes) {
  for (var key in attributes) {
    if (attributes[key] instanceof FileList) {
      return true
    } else if (typeof (attributes[key]) == 'object') {
      if (containsFileList(attributes[key]))
        return true
    }
  }
  return false
}

function attributesToFormData(obj, rootName) {
  var formData = new FormData()

  function appendFormData(data, root) {
    root = root || ''
    if (data instanceof File) {
      formData.append(root, data)
    } else if (Array.isArray(data)) {
      for (var i = 0; i < data.length; i++) {
        appendFormData(data[i], root + '[' + i + ']')
      }
    } else if (typeof data === 'object' && data) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          if (root === '') {
            appendFormData(data[key], key)
          } else {
            appendFormData(data[key], root + '[' + key + ']')
          }
        }
      }
    } else {
      if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data)
      }
    }
  }

  appendFormData(obj, rootName)

  return formData
}

function makeDataset(data) {
  var result = {}
  for (var key in data) {
    result[`data-${key.replace(/[A-Z]/g, m => "-" + m.toLowerCase())}`] = present(data[key]) ? data[key] : ''
  }
  return result
}

function stringBooleanToString(value) {
  if (value == 'true') {
    return true
  } else if (value == 'false') {
    return false
  } else {
    return value
  }
}

function pluck(array, key = 'id') {
  return array.map(item => item[key])
}

function indexBy(array, key = 'id') {
  return array.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})
}

function indexWith(array, value = true) {
  if (typeof value == 'function') {
    return array.reduce((obj, item) => {
      obj[item] = value(item)
      return obj
    }, {})
  } else {
    return array.reduce((obj, item) => {
      obj[item] = value
      return obj
    }, {})
  }
}

function mountComponent(node, component) {
  const propsJson = node.getAttribute('data-react-props')
  const props = propsJson && JSON.parse(propsJson)
  const reactElement = React.createElement(component, props)
  ReactDOM.render(reactElement, node)
}

function addCsrfHeader(headers = null) {
  if (!headers) headers = {}

  headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content
  return headers
}

function present(value) {
  if (value === null || value === undefined)
    return false
  if (Array.isArray(value))
    return value.length > 0
  if (typeof value == 'object')
    return Object.keys(value).length > 0
  if (value === 0)
    return true
  return !!value
}

function titleize(str) {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, l => l.charAt(0).toUpperCase() + l.substr(1).toLowerCase())
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function sortBy(arr, fn) {
  return arr.sort((a, b) => {
    const fa = fn(a)
    const fb = fn(b)
    if (fa < fb) return -1
    if (fa > fb) return 1
    return 0
  })
}

function enarray(value) {
  if (value === null || value === undefined)
    return []
  if (Array.isArray(value))
    return value
  return [value]
}

function partition(arr, fn) {
  return arr.reduce((acc, val, i) => {
    acc[fn(val, i) ? 0 : 1].push(val)
    return acc
  }, [[], []])
}

function calculateDateSpan(start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const diff = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
  return diff + 1
}

export {
  dataset,
  parseDataset,
  summarizeYearList,
  range,
  yearRange,
  invertObject,
  yearsAgo,
  dateFormat,
  capitalize,
  intersection,
  intersects,
  difference,
  containsFileList,
  attributesToFormData,
  makeDataset,
  stringBooleanToString,
  pluck,
  indexBy,
  indexWith,
  clamp,
  mountComponent,
  addCsrfHeader,
  present,
  titleize,
  isEmpty,
  sortBy,
  enarray,
  partition,
  calculateDateSpan,
  arrayEqual,
}
